import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";
import SiteService from "@/core/services/site.service.js";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  currentEventCollection(state) {
    return state.collection;
  },
};

const actions = {
  fetchCurrentEvent(context) {
    return new Promise((resolve) => {
      let appLocale = i18nService.getUserActiveLanguage();
      ApiService.post(
        "events/admin/" + SiteService.getActiveSiteId() + "/backend_items/",
        {
          status: null,
          showArchiveEvent: false,
          sortBy: ["date_from"],
        }
      ).then(({ data }) => {
        //console.log(data);
        let dataLocalized = data.collection.map((item) => {
          // item.created = new Date(item.created_at).toLocaleString("hu-HU", {
          //   timeZone: "UTC",
          // });

          // item.from = !item.date_from
          //   ? ""
          //   : new Date(item.date_from).toLocaleString("hu-HU", {
          //       timeZone: "UTC",
          //     });

          // item.to = !item.date_to
          //   ? ""
          //   : new Date(item.date_to).toLocaleString("hu-HU", {
          //       timeZone: "UTC",
          //     });

          item.date_from = !item.date_from
            ? ""
            : new Date(item.date_from).toLocaleString("hu-HU", {
                timeZone: "Europe/Budapest",
              });
          item.date_to = !item.date_to
            ? ""
            : new Date(item.date_to).toLocaleString("hu-HU", {
                timeZone: "Europe/Budapest",
              });

          if (item.translations) {
            let translations = item.translations[appLocale];
            if (translations) {
              Object.keys(translations).forEach((property) => {
                item[property] = item.translations[appLocale][property];
              });
              item.translated = true;
            } else {
              for (const [key, value] of Object.entries(
                item.translations[Object.keys(item.translations)[0]]
              )) {
                if ("attachments" != key) {
                  item[key] = value;
                }
              }
            }
          }

          return item;
        });
        context.commit("fetchCurrentEvent", dataLocalized);
        resolve();
      });
    }).catch((error) => {
      console.log("Error!: ", error);
    });
  },
};

const mutations = {
  fetchCurrentEvent(state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
